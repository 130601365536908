<template>
    <b-card>
       <div class="d-flex flex-row justify-content-between">
             <!-- about -->
        <div v-if="aboutData.bio">
            <h5 class="text-capitalize mb-75">Bio</h5>
            <b-card-text>
                {{ aboutData.bio }}
            </b-card-text>
        </div>

        <div class="mt-1">
            <h5 class="text-capitalize mb-75">Name</h5>
            <b-card-text>
                {{ aboutData.name }}
            </b-card-text>
        </div>
        <div class="mt-1">
            <h5 class="text-capitalize mb-75">Email</h5>
            <b-card-text>
                {{ aboutData.email }}
            </b-card-text>
        </div>
        <div class="mt-1">
            <h5 class="text-capitalize mb-75">Gender</h5>
            <b-card-text>
                {{ aboutData.gender }}
            </b-card-text>
        </div>
       </div>
    </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
    components: {
        BCard,
        BCardText,
    },
    props: {
        aboutData: {
            type: Object,
            default: () => {},
        },
    },
    created() {
        // console.log(this.aboutData);
    },
};
</script>
