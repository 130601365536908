<template>
    <!-- <b-card
    class="profile-header mb-2"
    :img-src="headerData.coverImg"
    img-top
    alt="cover photo"
    body-class="p-0"
  > -->
    <b-card>
        <!-- profile picture -->
        <div class="">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row">
                    <b-img :src="headerData.image" class="img" rounded fluid alt="profile photo" />
                </div>
                <!-- profile title -->
                <div class="profile-title">
                    <div class="d-flex flex-row">
                        <h2 class="text-white d-flex flex-row">
                            {{ headerData.user_name }}
                        </h2>
                        <b-img v-if="headerData.have_verify_tick == 1" class="img1 mx-1" :src="require('@/assets/images/logo/verifyTick1.png')" />
                    </div>
                    <p class="text-white">
                        {{ headerData.role }}
                    </p>
                </div>
                <!--/ profile title -->
            </div>
        </div>
        <!--/ profile picture -->

        <!-- profile navbar -->
        <!-- <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                active
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Feed</span>
                <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">About</span>
                <feather-icon
                  icon="InfoIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Photos</span>
                <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Friends</span>
                <feather-icon
                  icon="UsersIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>

            <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Edit</span>
              </b-button>
            </template>
          </b-tabs>

        </b-collapse>
      </b-navbar>
    </div> -->
        <!--/ profile navbar -->
    </b-card>
</template>

<script>
import { BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BBadge } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BCard,
        BTabs,
        BButton,
        BNavItem,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BImg,
        BBadge,
    },
    directives: {
        Ripple,
    },
    props: {
        headerData: {
            type: Object,
            default: () => {},
        },
    },
    created() {},
};
</script>
<style scoped>
.img {
    width: 150px;
}
.img1 {
    width: 25px;
    height: 25px;
    margin-top: 3px;
}
</style>
