import {ref, watch} from '@vue/composition-api'

// Notification
import profileRepository from '@/abstraction/repository/profileRepository'

const repository = new profileRepository()
export default function usemoodList() {
    const refplaylistListTable = ref(null)
    const profile = ref([])
    const sortBy = ref('id')
    const searchQuery = ref('')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const pagination = ref({})

    // Table Handlers
    const tableColumns = [
        { key: 'list', sortable: true },
        { key: 'music', sortable: true },
        { key: 'genre', sortable: true },
        { key: 'actions' },
    ]

    const storePlaylist = async data => {
        try {
            return await repository.storeWithSlug(data)
        } catch (e) {
            return false
        }
    }

    const showPlaylist = async id => {
        try {
            return await repository.show(id)
        } catch (e) {
            return false
        }
    }

    const updatePlaylist = async (id, data) => {
        try {
            return await repository.update(id, data)
        } catch (e) {
            return false
        }
    }

    const fetchProfile = async () => {
        const profileList = await repository.index()
        profile.value = profileList.data
        return profile
    }


    const deletePlaylist = async (id) => {
        try {
            const response = await repository.delete(id)
            pagination.value.itemsLength--
            const index = profile.value.findIndex(x => x.id === id)
            profile.value.splice(index, 1)
            return response
        } catch (e) {
            return false
        }
    }
    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        profile,
        pagination,
        sortBy,
        storePlaylist,
        showPlaylist,
        updatePlaylist,
        fetchProfile,
        deletePlaylist,
    }
}
